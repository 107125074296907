import React from "react";
import { Link } from "react-router-dom";
import Contact from "../cards/newscard/sideBarComponents/Contact";
import { useTheme } from "../../../Dashboard/ThemeContext";
import myImg from "./logo.jpg";
import { ArrowRightOutlined, YoutubeOutlined } from "@ant-design/icons";
const Footer = () => {
  const { themeColor } = useTheme();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          // minHeight: "50vh",
          background: `linear-gradient(to bottom, #bd354e,${themeColor} )`,
        }}
      >
        <hr
          className="my-10"
          style={{ border: "none", borderTop: "2px solid #999c98" }}
        />
        <div className="h-full" style={{ width: "95%", padding: "50px auto" }}>
          <div className="grid grid-cols-4 h-full gap-[30px] sm:gap-0 py-[30px]">
            <div className="col-span-4 sm:col-span-1 text-white flex flex-col  items-center h-full">
              {/* <h2 className="text-[25px] text-white font-bold">
                लोकपाटी मिडिया प्रा.लि
              </h2> */}
              <img src={myImg} alt="logo" className="w-[200px]" />
              <h2 className="text-sm mb-[20px] mt-[5px]">
                <h3>Best News Portal of Nepal,</h3>
                <h3> नेपालको उत्कृष्ट न्युज पोर्टल</h3>
              </h2>
              <h2 className="text-[16px] text-white">
                सूचना विभाग दर्ता नं. : ९८८
              </h2>
              <h2 className="text-[16px] text-white">Anamnagar, Kathmandu</h2>
              <h2 className="text-[16px] text-white">
                (सिंहदरबारको पूर्वीगेट)
              </h2>
              <h2 className="text-[16px] text-white">Phone: 9863032709</h2>
              <h2 className="text-[16px] text-white">
                News: lokpatinews@gmail.com
              </h2>
              <h2 className="text-[16px] text-white">
                Market: lokpatiadd@gmail.com
              </h2>
            </div>
            <div className="col-span-4 sm:col-span-1 flex flex-col  items-center gap-[10px]">
              <h2 className="text-[18px] font-bold text-white">
                प्रबन्ध निर्देशक
              </h2>
              <h2 className="text-[16px] font-bold text-white">
                जनार्दन बाबु कट्टेल
              </h2>
              <h2 className="text-[18px] font-bold text-white">सम्पादक</h2>
              <h2 className="text-[16px] font-bold text-white">
                विशेश्वर कट्टेल
              </h2>
              <h2 className="text-[18px] font-bold text-white">प्रबन्धक</h2>
              <h2 className="text-[16px] font-bold text-white">माधव भण्डारी</h2>
              <div
                className="px-[10px] py-[4px] flex justify-center items-center text-white "
                style={{ border: "2px solid #302a96", borderRadius: "10px" }}
              >
                <Link to="/member" onClick={scrollToTop}>
                  View all <ArrowRightOutlined />
                </Link>
              </div>
            </div>
            <div className="col-span-4 sm:col-span-1 flex flex-col  items-center gap-[10px] text-white text-[18px] text-underline">
              <Link to="/" onClick={scrollToTop}>
                Terms and conditions
              </Link>
              <Link to="/about" onClick={scrollToTop}>
                About us
              </Link>
              <Link to="/contact" onClick={scrollToTop}>
                Contact us
              </Link>
            </div>
            <div className="col-span-4 sm:col-span-1 flex flex-col  items-center gap-2">
              <h2 className="text-[30px] text-white">Follow Us</h2>
              <div className="flex justify-center flex-col gap-[20px]">
                <div className="flex gap-[20px]">
                  <span className="">
                    <a
                      href="https://www.facebook.com/lokpationline"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center shadow-md"
                    >
                      <ion-icon name="logo-facebook" className="text-4xl" />
                    </a>
                  </span>

                  {/* Instagram Icon */}
                  <span className="">
                    <a
                      href="https://www.instagram.com/lokpatinews/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="w-12 h-12 bg-gradient-to-r from-pink-500 to-yellow-500 text-white rounded-full flex items-center justify-center shadow-md"
                    >
                      <ion-icon
                        name="logo-instagram"
                        style={{
                          color: "white",
                        }}
                        className="text-3xl p-2 rounded-full"
                      />
                    </a>
                  </span>
                </div>
                <div className="flex gap-[20px]">
                  <span className="">
                    <a
                      href="https://x.com/lokpatimedia"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="w-12 h-12 bg-black text-white rounded-full flex items-center justify-center shadow-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          fill="currentColor"
                          d="M9.333 6.929L14.546 1H13.31L8.783 6.147L5.169 1H1l5.466 7.783L1 15h1.235l4.779-5.436L10.83 15H15zM7.641 8.852l-.554-.776L2.68 1.911h1.898l3.557 4.977l.552.776l4.623 6.47h-1.897z"
                        />
                      </svg>
                    </a>
                  </span>

                  {/* YouTube Icon */}
                  <span className="flex items-center justify-center">
                    <a
                      href="https://www.youtube.com/channel/UCWyvMjBZ5h4dG6_pKJBInYg"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="w-12 h-12 bg-red-600 text-white rounded-full flex items-center justify-center shadow-md"
                    >
                      <YoutubeOutlined className="text-3xl" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ border: "none", borderTop: "1px solid #ebebfa" }} />
      <div
        style={{
          height: "70px",
          width: "100%",
          marginTop: "0", // Ensure no gap
          backgroundColor: `${themeColor}`,
        }}
        className="flex justify-center items-center px-2 sm:px-0"
      >
        <h2 className="text-l" style={{ color: "white" }}>
          Copyright © 2024 | all rights reserved by Lokpati media
        </h2>
        <hr className="border-l-2 border-gray-300 mx-2 h-[40px] sm:h-[30px]" />
        <h2 className="text-l" style={{ color: "white" }}>
          Developed by{" "}
          <a
            href="https://tachyonwave.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            Tachyonwave
          </a>
        </h2>
      </div>
    </>
  );
};

export default Footer;
