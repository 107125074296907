import React, { useState, useEffect } from "react";
import TrendingNewsBox from "./TrendingNewsBox";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../navigation/Breadcrumb";
import { useTheme } from "../../../../../Dashboard/ThemeContext";
import { useNews } from "../../../../../Dashboard/NewsContext";
import { useCount } from "../../../../../Dashboard/CountContext";

const nepaliNumbers = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];

const toNepaliNumber = (num) => {
  return String(num)
    .split("")
    .map((digit) => nepaliNumbers[Number(digit)])
    .join("");
};

export default function TrendingNews({ myBreadcrumb = true }) {
  const navigate = useNavigate();
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );
  const { themeColor } = useTheme();
  const { wholeNews } = useNews();
  const { count } = useCount();
  const [trendingNews, setTrendingNews] = useState([]);

  useEffect(() => {
    const fetchTrendingNews = async () => {
      try {
        const viewsResponse = await count;
        if (!viewsResponse || !Array.isArray(viewsResponse)) return;

        const sortedViews = viewsResponse.sort(
          (a, b) => b.visit_count - a.visit_count
        );
        // .slice(0, 5); // Keep only the top 5

        const topTitles = sortedViews.map((item) => String(item.title));
        const newsResponse = await wholeNews;

        const trendingData = sortedViews
          .map((view) => {
            const matchingNews = newsResponse.find(
              (news) => String(news.id) === view.title && news.language === lge
            );
            if (matchingNews) {
              return {
                id: matchingNews.id,
                created_date_ad: matchingNews.created_date_ad,
                created_date_bs: matchingNews.created_date_bs,
                title: matchingNews.news_title,
                subtitle: matchingNews.news_sub_title,
                image: matchingNews.image,
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        setTrendingNews(trendingData.slice(0, 6)); // Ensure only 5 items are set
      } catch (error) {
        console.error("Error fetching trending news:", error);
      }
    };

    fetchTrendingNews();
  }, [count, wholeNews]);

  return (
    <div className="px-5">
      {myBreadcrumb && (
        <Breadcrumb
          myWord={lge === "en" ? "Trending" : "लोकप्रिय"}
          addNews={false}
        />
      )}

      {/* <div className="  lg:w-auto"> */}
      {trendingNews.map((news, index) => (
        <React.Fragment key={news.id}>
          <div className="grid grid-cols-7 gap-5 my-1">
            <div className="col-span-1 flex justify-center items-center">
              <span
                className="text-white font-bold border w-[30px] h-[30px] flex justify-center items-center bg-blue-600"
                style={{
                  borderRadius: "100%",
                }}
              >
                {lge === "en" ? index + 1 : toNepaliNumber(index + 1)}
              </span>
            </div>
            <div className="col-span-6 ">
              <TrendingNewsBox
                id={news.id}
                created_date_ad={news.created_date_ad}
                created_date_bs={news.created_date_bs}
                title={news.title}
                subtitle={news.subtitle}
                image={news.image}
              />
            </div>
          </div>
          {index < trendingNews.length - 1 && (
            <hr className="bg-[#d1d1cf] mx-2 h-[2px] " />
          )}
        </React.Fragment>
      ))}
      {/* </div> */}
    </div>
  );
}
