import React, { useEffect, useState } from "react";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import myLogo from "./logo.png";
import { Popover } from "antd";
import { color } from "framer-motion";
import { Select } from "antd";
import { useNavigation } from "./NavigationContext";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { Post } from "../redux/features/API";
import { message } from "antd";
import { useNewsSearch } from "./searchNewsContext";
export default function Navigation({ open, setOpen }) {
  const { lge, setLge } = useNavigation();
  const { setSearchValue } = useNewsSearch();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };
  const isNewsPage = location.pathname.includes("/dashboard/news");

  const logout = async () => {
    try {
      await Post({ url: "/user/logout/", headers }); // Make sure this API call is correct
      localStorage.removeItem("Token"); // Properly remove the token
      message.success("Successfully logged out");
      navigate("/dashboard/login"); // Redirect to login page (or wherever you want)
    } catch (error) {
      message.error("Error on logout");
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const [popOpen, setPopOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "np",
    label: "Nepali",
  });
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setLge(selectedOption);
  };
  const hide = () => {
    setPopOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setPopOpen(newOpen);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission if in a form
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    setSearchValue(search);
  };
  const searchChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div
      style={{ minHeight: "15vh", backgroundColor: "#192b47" }}
      className=" grid grid-cols-4 w-full md:w-[100%]"
    >
      <SideBar open={open} setOpen={setOpen} />
      <div className="col-span-2 md:col-span-1 flex items-center justify-center">
        <img
          src={myLogo}
          alt="logo"
          style={{ width: "200px", height: "70%" }}
          className="cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className=" col-span-2 hidden md:flex items-center justify-center">
        {/* {isNewsPage && ( */}
        <div className="flex w-full items-center">
          <input
            className="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-blue-100 border-0 rounded-l-md focus:placeholder-gray-500 focus:bg-blue-50 focus:border-purple-300 focus:outline-none focus:shadow-outline-purple h-10"
            type="text"
            value={search}
            placeholder="Search here...."
            onKeyDown={handleKeyDown}
            aria-label="Search"
            onChange={searchChange}
          />
          <button
            className="bg-blue-500 text-white rounded-r-md px-4 h-10"
            onClick={handleSubmit}
          >
            <SearchOutlined />
          </button>
        </div>
        {/* // )} */}
      </div>
      <div className="col-span-2 md:col-span-1  flex items-center justify-center gap-[10px] md:gap-[20px]">
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={[
            { value: "en", label: "English" },
            { value: "np", label: "Nepali" },
            // { value: "bo", label: "Both" },
          ]}
        />
        ;
        <Popover
          content={
            <div>
              {/* <div className="text-xl  font-mukta flex items-center gap-[5px] hover:bg-red-100 p-[10px] cursor-pointer">
                <ion-icon name="person-circle-outline" size="large"></ion-icon>
                Admin
              </div> */}
              <div
                className="text-xl  font-mukta flex items-center gap-[5px] hover:bg-blue-900 hover:text-white p-[10px] cursor-pointer"
                onClick={logout}
              >
                <LogoutOutlined type="primary" style={{ fontSize: "25px" }} />
                SignOut
              </div>
            </div>
          }
          trigger="click"
          open={popOpen}
          onOpenChange={handleOpenChange}
        >
          <LogoutOutlined
            type="primary"
            style={{ fontSize: "25px", color: "white" }}
          />
        </Popover>
        <MenuUnfoldOutlined
          type="primary"
          style={{ fontSize: "25px", color: "white" }}
          onClick={showDrawer}
        />
      </div>
    </div>
  );
}
