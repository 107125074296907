import React from "react";
import Contact from "../../common/cards/newscard/sideBarComponents/Contact";
import MukhyaSamachar from "../../common/cards/newscard/sideBarComponents/MukhyaSamachar";
import TajaSamachar from "../../common/cards/newscard/sideBarComponents/TajaSamachar";
import Ads from "../../common/cards/Ads";
import TrendingNews from "../../common/cards/newscard/sideBarComponents/TrendingNews";
import SmallAds from "../../common/cards/SmallAds";
import Pravidi from "../../common/cards/newscard/sideBarComponents/Pravidi";

const SideContainer = ({ mukhyaShow }) => {
  return (
    <div className="w-full flex flex-col gap-[2px] h-full">
      <SmallAds name="H_sidebar_before_followus1" />
      <SmallAds name="H_sidebar_before_followus2" />
      <div className="mt-2 w-full">
        {!mukhyaShow && (
          <>
            <h2 className="text-xl font-bold">Follow Us:</h2>
            <Contact />
          </>
        )}
        <div className="w-full flex flex-col gap-[20px]">
          <SmallAds name="H_sidebar_after_followus1" />
          <SmallAds name="H_sidebar_after_followus2" />
        </div>
      </div>
      <div className="sticky top-[60px]">
        <Pravidi />
      </div>
    </div>
  );
};

export default SideContainer;
