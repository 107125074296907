import React, { useEffect, useState } from "react";
import logo from "./logo.png";
import { Route, Routes, useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import SideBar from "./SideBar";
import Dashboard from "../User/page/Login/Dashboard";
import Category from "../User/page/Login/Category";
import SubCategory from "../User/page/Login/SubCategory";
import SubSubCategory from "../User/page/Login/SubSubCategory";
import Authors from "../User/page/Login/Authors";
import Contact from "../User/page/Login/Contact";
import News from "../User/page/Login/News";
import Advertisement from "../User/page/Login/Advertisement";
import { Helmet } from "react-helmet";
import Notice from "../User/page/Login/Notice";
import Photos from "../User/page/Login/Photos";
import Videos from "../User/page/Login/Videos";
import FooterDashboard from "../User/page/Login/FooterDashboard";
import Review from "../User/page/Login/Review";
import { useNavigate } from "react-router-dom";
import Members from "../User/page/Login/Members";

const PageDashboard = () => {
  const [open, setOpen] = useState(false);
  const [myLink, setMyLink] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const isDashboardRoute = location.pathname.startsWith("/dashboard");
  const isLoginRoute = location.pathname.includes("/dashboard/login");

  useEffect(() => {
    const formattedLink = location.pathname.slice(1); // Remove the first "/"
    const capitalizedLink = formattedLink
      .split("/")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join("/");
    setMyLink(capitalizedLink);
  }, [location.pathname]); // Dependency array to update when the path changes
  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (!token) {
      navigate("/dashboard/login");
    }
  });
  return (
    <div style={{ width: "100%" }} className="flex flex-col items-center ">
      {isDashboardRoute && (
        <>
          <Helmet>
            {/* <link rel="icon" href={logo} type="image/x-icon" /> */}
            <link rel="apple-touch-icon" href={logo} />
            <title>Lokpati-Dashboard</title>
            <meta
              property="og:description"
              content="Lokpati Dashboard page"
              c
            />
          </Helmet>
          {!isLoginRoute && <Navigation open={open} setOpen={setOpen} />}
          <div className="flex w-[97%] md:w-[90%]">
            <div className="w-full min-h-[85vh] bg-blue-100">
              <div>
                <h2 className="text-l px-3  my-5"> {myLink}</h2>{" "}
                {/* Display the formatted link */}
              </div>
              <div>
                <Routes>
                  <Route path="" element={<Review />} />
                  <Route path="category" element={<Category />} />
                  <Route path="sub-category" element={<SubCategory />} />
                  {/* <Route path="sub-sub-category" element={<SubSubCategory />} /> */}
                  <Route path="authors" element={<Authors />} />
                  <Route path="members" element={<Members />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="news" element={<News />} />
                  <Route path="advertisement" element={<Advertisement />} />
                  <Route path="footer" element={<FooterDashboard />} />
                  {/* <Route path="notice" element={<Notice />} /> */}
                  {/* <Route path="gallery" element={<Photos />} />
                  <Route path="videos" element={<Videos />} /> */}
                  <Route path="*" element={<div>Route not found</div>} />
                </Routes>
              </div>
              <footer
                style={{ minHeight: "5vh" }}
                className="flex items-center"
              >
                {/* <div
                  style={{
                    height: "70px",
                    width: "100%",
                    // backgroundColor: "#2d5e29",
                    marginTop: "50px",
                    // backgroundColor: themeColor,
                  }}
                  className={`flex flex-wrap  justify-center items-center gap-2 px-2 sm:px-0 `}
                >
                  <h2 className=" text-l " style={{ color: "#878684" }}>
                    Copyright © 2024 Lokpati.com
                  </h2>
                  <h2 className=" text-l " style={{ color: "#878684" }}>
                    |
                  </h2>
                  <a
                    href="https://tachyonwave.com/"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className=" text-[#878684] text-l"
                  >
                    Developed by Tachyonwave
                  </a>
                </div> */}
                <div
                  style={{
                    height: "70px",
                    width: "100%",
                    // backgroundColor: "#2d5e29",
                    marginTop: "50px",
                    height: "70px",
                    width: "100%",
                    marginTop: "0", // Ensure no gap
                    // backgroundColor: `${themeColor}`,
                  }}
                  className="flex justify-center items-center px-2 sm:px-0"
                >
                  <h2 className="text-l" style={{ color: "black" }}>
                    Copyright © 2024 | all rights reserved by Lokpati media
                  </h2>
                  <hr className="border-l-2 border-gray-800 mx-2 h-[40px] sm:h-[30px]" />
                  <h2 className="text-l" style={{ color: "black" }}>
                    Developed by{" "}
                    <a
                      href="https://tachyonwave.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      Tachyonwave
                    </a>
                  </h2>
                </div>
              </footer>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PageDashboard;
