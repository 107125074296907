import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function TrendingNewsBox({
  id,
  created_date_bs,
  created_date_ad,
  title,
  subtitle,
  image,
}) {
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Link
      to={
        lge === "en"
          ? `/en/story/${created_date_ad.split("T")[0].split("-").join("/")}/${id}/${title}`
          : `/story/${created_date_bs ? created_date_bs.replace(/-/g, "/") : "default_date"}/${id}` // Add a fallback or default date
      }
    >
      <div
        style={{
          padding: "10px 0px",
        }}
        className="grid grid-cols-5 h-[100px] cursor-pointer gap-[20px]"
        onClick={scrollToTop}
      >
        <span className="col-span-3">
          <p
            className="font-bold font-mukta text-[20px] line-clamp-3 text-[rgba(0,0,0,0.7)] hover:text-blue-600 duration-150"
            style={{ lineHeight: "1.5" }}
          >
            {title}
          </p>
        </span>
        {image ? (
          <img
            src={image}
            alt=""
            style={{ borderRadius: "5px" }}
            className="ml-[2px] w-full h-[80px] object-cover col-span-2"
          />
        ) : (
          <div className="bg-gray-200 w-full h-full col-span-2"></div>
        )}
      </div>
    </Link>
  );
}
