import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faFacebookMessenger, // Import the Messenger icon
} from "@fortawesome/free-brands-svg-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton, // Import the MessengerShareButton
} from "react-share";
import { message } from "antd/es";
import { useCount } from "../../../Dashboard/CountContext";
import { Post } from "../../../redux/features/API";

const Share = ({ newsTitle, id, shareCount }) => {
  const newsUrl = window.location.href;
  // useEffect(() => {
  //   console.log(newsTitle);
  //   console.log(id);
  // }, []);
  const handleShare = async () => {
    const share_count = shareCount + 2; // No need to stringify here
    const myShare = { share_count }; // Create the object directly
    console.log("myShare is ", myShare);

    try {
      const response = await Post({
        url: `/count/share/${id}/store_share_count/`,
        data: myShare, // Use 'data' to send the body of the request
      });

      console.log(response);
    } catch (error) {
      console.error("Error updating share count:", error);
    }
  };

  return (
    <div className="flex gap-[10px]">
      <div className="flex  items-center justify-center gap-[5px]">
        <h2 className="text-[20px] font-bold  text-[#8a8986]">
          {shareCount || "0"}
        </h2>
        <h2 className="text-[12px]  text-[#8a8986]">Shares</h2>
      </div>
      <div className="flex gap-[10px]">
        <FacebookShareButton
          url={newsUrl}
          quote={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          <FontAwesomeIcon
            icon={faFacebook}
            className="text-[30px] duration-[0.3s] hover:text-blue-800 hover:scale-[1.3]"
          />
        </FacebookShareButton>

        <TwitterShareButton
          url={newsUrl}
          title={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <FontAwesomeIcon icon={faTwitter} /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28px"
            height="28px"
            viewBox="0 0 16 16"
            className="duration-[0.3s] hover:text-black hover:scale-[1.3]"
          >
            <path
              fill="currentColor"
              d="M9.333 6.929L14.546 1H13.31L8.783 6.147L5.169 1H1l5.466 7.783L1 15h1.235l4.779-5.436L10.83 15H15zM7.641 8.852l-.554-.776L2.68 1.911h1.898l3.557 4.977l.552.776l4.623 6.47h-1.897z"
              className="w-5 h-5"
            />
          </svg>
        </TwitterShareButton>

        <WhatsappShareButton
          url={newsUrl}
          title={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          <FontAwesomeIcon
            icon={faWhatsapp}
            className="text-[30px] duration-[0.3s] hover:text-blue-600 hover:scale-[1.3]"
          />
        </WhatsappShareButton>

        <FacebookMessengerShareButton
          url={newsUrl}
          title={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          <FontAwesomeIcon
            icon={faFacebookMessenger}
            className="text-[30px] duration-[0.3s] hover:text-[rgb(32,94,233)] hover:scale-[1.3]"
          />
        </FacebookMessengerShareButton>
      </div>
    </div>
  );
};

export default Share;
