import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import DefaultImg from "../logo.png";
const SmallCardContentRight = ({
  showParagraph = false,
  textBlack = false,
  id,
  title,
  sub_title,
  image,
  created_date_ad,
  created_date_bs,
}) => {
  const [lge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const formattedAdDate =
    created_date_ad?.split("T")[0].split("-").join("/") || "";
  const formattedBsDate = created_date_bs?.replace(/-/g, "/") || "";

  return (
    <div className="w-full group cursor-pointer h-full overflow-hidden font-mukta">
      <Link
        to={
          lge === "en"
            ? `/en/story/${formattedAdDate}/${id}/${title}`
            : `/story/${formattedBsDate}/${id}`
        }
      >
        <div className="flex h-full gap-5" onClick={scrollToTop}>
          <div className="w-[150px] overflow-hidden h-[100px] relative flex justify-center group bg-gray-200">
            {image !== "" ?(
              <img
                src={image}
                alt=""
                className="w-full h-[100px] max-w-full group-hover:opacity-80 group-hover:scale-105 transition-transform duration-200   rounded-sm"
              />
            ):
            (
              <img
                src={DefaultImg}
                alt=""
                className="w-full h-[100px] max-w-full group-hover:opacity-80 group-hover:scale-105 transition-transform duration-200   rounded-sm"
              />
            )
            }
          </div>
          <div className="w-[60%] flex flex-col gap-1">
            <h2
              className={`${textBlack ? "text-black" : "text-white"} text-l md:text-2xl line-clamp-3 !font-medium`}
              style={{ lineHeight: "1.5" }}
            >
              {title}
            </h2>
            {showParagraph && (
              <p
                className={`${textBlack ? "text-black/80" : "text-white/80"} line-clamp-2 !font-normal`}
                style={{ lineHeight: "1.5" }}
              >
                {sub_title}
              </p>
            )}
            {/* Additional content can go here */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SmallCardContentRight;
