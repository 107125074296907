import React, { useState, useEffect } from "react";
import { Button, Table, Modal, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CategoryAdd from "./Buttons/CategoryAdd";
import CategoryModify from "./Buttons/CategoryModify";
import { Get, Delete } from "../../../redux/features/API";
import { useNavigation } from "../../../Dashboard/NavigationContext";
import { useNewsSearch } from "../../../Dashboard/searchNewsContext";

const columns = (showModal, handleDelete) => [
  {
    title: "Display order",
    dataIndex: "order",
  },
  {
    title: "Category",
    dataIndex: "category_name",
  },
  {
    title: "Subcategory",
    dataIndex: "subcategory_key_name",
  },
  {
    title: "Display Order",
    dataIndex: "display_order",
  },
  {
    title: "Active",
    dataIndex: "active",
    render: (active) => (active ? "Yes" : "No"),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          type="primary"
          onClick={() => showModal(record)}
          className="bg-white text-black"
        >
          <EditOutlined />
        </Button>
        <Button type="danger" onClick={() => handleDelete(record)}>
          <DeleteOutlined />
        </Button>
      </div>
    ),
  },
];

const CategoryTable = ({ reload, setReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lge } = useNavigation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { searchValue } = useNewsSearch();

  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };

  const showModal = (category) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  const resetModal = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData();
    setReload(false);
  }, [reload, lge, searchValue]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const hasContent = searchValue && /\S/.test(searchValue);
      const url = hasContent
        ? `/search/search/?q=${searchValue}`
        : "/category/category";

      const response = await Get({
        url,
        headers: hasContent ? undefined : headers,
      });

      // console.log("API Response:", response); // Log the response
      const responseData = hasContent
        ? response.category || []
        : response || [];

      if (!responseData || (hasContent && !response.category)) {
        throw new Error("Invalid response structure");
      }

      const filteredResponse = responseData
        .filter((item) => item.language === lge)
        .sort((a, b) => a.display_order - b.display_order);

      const transformedData = filteredResponse.map((item) => ({
        key: item.id,
        order: item.display_order,
        category_name: item.category_name,
        subcategory_key_name: item.category_key?.length || "0", // Safely check for length
        display_order: item.display_order,
        active: item.active,
      }));

      setDataSource(transformedData);
    } catch (error) {
      console.error("Error fetching categories:", error);
      message.error("Failed to load categories.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      content: `Category: ${record.category_name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await Delete({ url: `/category/category/${record.key}`, headers });
          message.success("Category deleted successfully.");
          setDataSource((prev) =>
            prev.filter((item) => item.key !== record.key)
          );
        } catch (error) {
          console.error("Error deleting category:", error);
          message.error("Failed to delete category.");
        } finally {
          fetchData();
        }
      },
    });
  };

  return (
    <>
      <Table
        columns={columns(showModal, handleDelete)}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
      <Modal
        title="Modify Category"
        open={isModalOpen}
        onOk={resetModal}
        onCancel={resetModal}
        footer=""
        okButtonProps={{ style: { color: "black" } }}
      >
        <CategoryModify
          modifyObj={selectedCategory}
          fetchData={fetchData}
          handleCancel={resetModal}
        />
      </Modal>
    </>
  );
};

export default CategoryTable;
