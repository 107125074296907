import { YoutubeOutlined } from "@ant-design/icons";
import { BorderRight } from "@mui/icons-material";
import React from "react";

export default function Contact({ vertical = false }) {
  return (
    <div
      className={`contact_icon ${
        vertical ? "flex flex-col" : "flex"
      } justify-center items-center gap-6 my-6`}
      style={{
        width: "100%",
      }}
    >
      {/* Facebook Icon */}
      <span className="">
        <a
          href="https://www.facebook.com/lokpationline"
          target="_blank"
          style={{ textDecoration: "none" }}
          className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center shadow-md"
        >
          <ion-icon name="logo-facebook" className="text-3xl" />
        </a>
      </span>

      {/* Instagram Icon */}
      <span className="">
        <a
          href="https://www.instagram.com/lokpatinews/"
          target="_blank"
          style={{ textDecoration: "none" }}
          className="w-12 h-12 bg-gradient-to-r from-pink-500 to-yellow-500 text-white rounded-full flex items-center justify-center shadow-md"
        >
          <ion-icon
            name="logo-instagram"
            style={{
              color: "white",
            }}
            className="text-3xl p-2 rounded-full"
          />
        </a>
      </span>

      {/* Twitter Icon (using SVG) */}
      <span className="">
        <a
          href="https://x.com/lokpatimedia"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          className="w-12 h-12 bg-black text-white rounded-full flex items-center justify-center shadow-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            className="w-6 h-6 text-white"
          >
            <path
              fill="currentColor"
              d="M9.333 6.929L14.546 1H13.31L8.783 6.147L5.169 1H1l5.466 7.783L1 15h1.235l4.779-5.436L10.83 15H15zM7.641 8.852l-.554-.776L2.68 1.911h1.898l3.557 4.977l.552.776l4.623 6.47h-1.897z"
            />
          </svg>
        </a>
      </span>

      {/* YouTube Icon */}
      <span className="flex items-center justify-center">
        <a
          href="https://www.youtube.com/channel/UCWyvMjBZ5h4dG6_pKJBInYg"
          target="_blank"
          style={{ textDecoration: "none" }}
          className="w-12 h-12 bg-red-600 text-white rounded-full flex items-center justify-center shadow-md"
        >
          <YoutubeOutlined className="text-3xl" />
        </a>
      </span>
    </div>
  );
}
